import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, TextField } from "@mui/material";
import { BookingApi } from "../../API/Apis/BookingApi";
import { t } from "i18next";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import { createTheme, Theme } from "@mui/material/styles";
import { formatThemeForReservedArea } from "../../config/MuiColorThemes";
import CollapsibleNavbar from "../../components/Layouts/ReservedAreaDashboard/CollapseNavbar";
import BookingGetters from "../../store/onlineBooking/getters";

export default function ChangePassword() {
  const [dynamicTheme, setDynamicTheme] = useState<Theme | null>(null);
  useEffect(() => {
    async function fetchTheme() {
      const dynamicPalette = await formatThemeForReservedArea();
      const theme = createTheme(dynamicPalette);
      setDynamicTheme(theme);
    }
    fetchTheme();
  }, []);
  function saveNewPassword() {
    if (changePassword.newPassword == changePassword.confirmPassword) {
      BookingApi.changePassword(changePassword)
        .then((e) => {
          if (e.status == "success") {
            alert("Password Updated successfully");
          }
          if (e.status == "error") {
            alert(e.errorMessage);
          }
          return e.data;
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      alert("The confirmed password does not match the password.");
    }
  }
  const handleOldPasswordChange = (event) => {
    const value = event.target.value;
    setChangePassword((prevState) => ({
      ...prevState,
      oldPassword: value,
    }));
  };

  const handleNewPasswordChange = (event) => {
    const value = event.target.value;
    setChangePassword((prevState) => ({
      ...prevState,
      newPassword: value,
    }));
  };

  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value;
    setChangePassword((prevState) => ({
      ...prevState,
      confirmPassword: value,
    }));
  };

  const [changePassword, setChangePassword] = useState<any>([
    {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  ]);
  const isSmallScreen = window.innerWidth <= 768;
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;
  const appSettings = BookingGetters?.getAppSettings();
  const [showReserve, setShowReserve] = useState<boolean>(false);
  useEffect(() => {
    setShowReserve(!!appSettings?.reservedArea);
  }, [appSettings]);
  return (
    <div>
      <div className="flex-row md:flex">
        {showReserve && !isSmallScreen && !istabletScreen && (
          <div className="!w-780" style={{ width: "300px" }}>
            <BookingDetalisListSideNavigation />
          </div>
        )}
        {showReserve && (isSmallScreen || istabletScreen) && (
          <CollapsibleNavbar callFrom="showReservations" />
        )}
        <div className=" flex-row md:flex flex-col pt-12">
          <div className="flex justify-between space-x-2 mb-8">
            <div style={{ padding: "20px" }}>
              <h1 className="font-medium text-[36px] text-[#232323] whitespace-nowrap">
                {t("Change Password")}
              </h1>
              <h4 className="text-[#838181] font-normal text-[20px]">
                {t("You can change your password from here.")}
              </h4>
              <br />
              <Card
                style={{ width: "100%", maxWidth: "600px", margin: "0 auto" }}
              >
                <CardContent>
                  <TextField
                    margin="normal"
                    type="password"
                    required
                    fullWidth
                    label={t("Old Password")}
                    name="oldPassword"
                    id="oldPassword"
                    onChange={handleOldPasswordChange}
                  />
                  <TextField
                    name="newPassword"
                    type="password"
                    id="newPassword"
                    margin="normal"
                    required
                    fullWidth
                    label={t("New Password")}
                    onChange={handleNewPasswordChange}
                  />
                  <TextField
                    name="confirmPassword"
                    id="confirmPassword"
                    type="password"
                    margin="normal"
                    required
                    fullWidth
                    label={t("Confirm Password")}
                    onChange={handleConfirmPasswordChange}
                  />
                  <div className="flex gap-4 flex-row-reverse mt-4">
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={saveNewPassword}
                      sx={{
                        gap: 3,
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        bgcolor: dynamicTheme?.palette?.primary?.main,
                      }}
                    >
                      {t("Save")}
                    </Button>
                    <Button
                      type="button"
                      variant="outlined"
                      sx={{
                        gap: 3,
                        fontWeight: "bold",
                        borderColor: "#000",
                        textTransform: "capitalize",
                      }}
                      style={{ color: "#000" }}
                    >
                      {t("Cancel")}
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
