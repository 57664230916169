import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MyReservationsPage from "../../../views/reservations";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import BookingApi from "../../../API/Apis/BookingApi";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NotAuthorisedPage from "../../../views/notAuthorisedPage";
import { BookingGetters } from "../../../store/onlineBooking/getters";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import { t } from "i18next";
import { AppStandardButton } from "../../UI/AppButtons";
import { formatTheme } from "../../../config/MuiColorThemes";

export default function SignUp() {
  const navigate = useNavigate();
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
    birthDate: Yup.string().nullable(),
    acceptTerms: Yup.bool().required("Accept Terms is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    BookingApi.createNewUser(data).then((data) => {
      <MyReservationsPage ids={data.data.id} />;

      if (data.data.status) {
        localStorage.setItem("userID", data.data.id);
        localStorage.setItem("isUserLoggedin", "true");
        navigate("/SignUpSuccess");
      } else {
        alert(data.data.message);
        if (data.data.message == "Email already exist") {
          reset({
            email: "",
          });
        }
        if (data.data.message == "Mobile number already exist") {
          reset({
            phoneNumber: "",
          });
        }
      }
    });
  };

  const appSettings = BookingGetters.getAppSettings();
  const [showReserve, setShowReserve] = useState<boolean>(
    !!appSettings?.reservedArea
  );

  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      setShowReserve(e.reservedArea);
    });
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const requestOnClose = () => {
    setOpenModal(false);
    setModalMessage("");
  };

  return (
    <ThemeProvider theme={dynamicTheme}>
      <div className="grid grid-cols-1 h-[100vh] md:grid-cols-2">
        <div className="">
          <img
            src="/assets/images/reserve_area_car.jpg"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col top-0 items-center w-full h-full min-h-screen">
          {showReserve ? (
            <div className="w-[100%]">
              <Container component="main" maxWidth="xs">
                <Grid container justifyContent="flex-start">
                  <Grid item>
                    <Link href="/signin" variant="body2">
                      <span className="text-[#004188]">
                        {t("Already have an account? Sign in")}
                      </span>
                    </Link>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    marginTop: 5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow: "none",
                  }}
                >
                  <h1
                    className="text-3xl font-semibold mb-4"
                    style={{
                      color: dynamicTheme?.palette["secondaryColor"]?.main,
                    }}
                  >
                    {t("Welcome to booking")}
                  </h1>
                  <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    sx={{
                      "& .MuiTextField-root": { mt: 2, width: "40ch" },
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="firstName"
                          label={t("First Name")}
                          autoFocus
                          {...register("firstName")}
                          error={!!errors.firstName}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.firstName?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="lastName"
                          label={t("Last Name")}
                          {...register("lastName")}
                          error={!!errors.lastName}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.lastName?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label={t("Email Address")}
                          {...register("email")}
                          error={!!errors.email}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.email?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="phoneNumber"
                          type="tel"
                          label={t("Phone Number")}
                          {...register("phoneNumber")}
                          error={!!errors.phoneNumber}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.phoneNumber?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          label={t("Password")}
                          type="password"
                          id="password"
                          {...register("password")}
                          error={!!errors.password}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.password?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          label={t("Confirm Password")}
                          type="password"
                          id="confirmPassword"
                          {...register("confirmPassword")}
                          error={!!errors.confirmPassword}
                        />
                        <Typography variant="inherit" color="textSecondary">
                          {errors.confirmPassword?.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="birthDate"
                          label={t("Date of birth")}
                          fullWidth
                          required
                          type="date"
                          {...register("birthDate")}
                          error={!!errors.birthDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            max:
                              new Date().getFullYear() -
                              18 +
                              "-" +
                              String(new Date().getMonth() + 1).padStart(
                                2,
                                "0"
                              ) +
                              "-" +
                              String(new Date().getDate()).padStart(2, "0"),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          aria-required={true}
                          className="select-none"
                          control={
                            <Checkbox
                              color="primary"
                              {...register("acceptTerms")}
                            />
                          }
                          label={
                            <Box
                              component="div"
                              fontSize={15}
                              color="#000000"
                              required
                            >
                              {t("Please accept Terms and condition")}
                            </Box>
                          }
                        />
                        <Typography variant="inherit" color="red">
                          {errors.acceptTerms
                            ? "(" + errors.acceptTerms.message + ")"
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1.5,
                        bgcolor: `${dynamicTheme?.palette.primary.main} !important`,
                        color: `${dynamicTheme?.palette?.["secondaryColor"].contrastText} !important`,
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {t("Register")}
                    </Button>
                  </Box>
                </Box>
              </Container>
            </div>
          ) : (
            <NotAuthorisedPage />
          )}
        </div>
        <Dialog
          open={openModal}
          onClose={requestOnClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ style: { borderRadius: "20px" } }}
        >
          <DialogTitle style={{ padding: "3rem" }}>{modalMessage}</DialogTitle>

          <DialogActions>
            <AppStandardButton variant="outlined" onClick={requestOnClose}>
              <p className="text-black normal-case">Close</p>
            </AppStandardButton>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
}
