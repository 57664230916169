import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Card,
  CardContent,
  Grid,
  CardHeader,
} from "@mui/material";
import { BookingApi } from "../../API/Apis/BookingApi";
import { t } from "i18next";
import TablePagination from "@mui/material/TablePagination";
import DefaultPage from "../../components/Layouts/DefaultPage";
import AlertMessage from "../AlertMessage";
import { createTheme, Theme } from "@mui/material/styles";
import { formatThemeForReservedArea } from "../../config/MuiColorThemes";

export default function InvoiceCard(props) {
  const [invoiceList, setInvoiceList] = useState([]);
  const [allInvoiceList, setAllInvoiceList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [dynamicTheme, setDynamicTheme] = useState<Theme | null>(null);
  const isSmallScreen = window.innerWidth <= 768;
  useEffect(() => {
    async function fetchTheme() {
      const dynamicPalette = await formatThemeForReservedArea();
      const theme = createTheme(dynamicPalette);
      setDynamicTheme(theme);
    }
    fetchTheme();
  }, []);
  const requestOnClose = () => {
    setOpenModal(false);
    setModalMessage("");
  };
  const handlePageChange = (event: unknown, page: number) => {
    setPage(page);
  };

  const handleRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const downloadInvoice = (invoiceId) => {
    if (invoiceId == "No Invoice Found") {
      setModalMessage("No Invoice Found");
      setOpenModal(true);
    } else {
      BookingApi.downloadInvoice(invoiceId).then((e) => {
        if (e.data.status == "error") {
          setModalMessage(e.data.errorMessage);
          setOpenModal(true);
        } else {
          const url = e.data;
          window.open(url, "_blank");
        }
      });
    }
  };
  useEffect(() => {
    if (props.searchInvoice != null && props.searchInvoice != "") {
      setInvoiceList(allInvoiceList);
      const searchTerm = props.searchInvoice
        .trim()
        .replace(/\s+/g, "")
        .toLowerCase();
      const filteredInvoices = allInvoiceList.filter((invoice) => {
        const prefix = invoice?.prefix.toLowerCase();
        const number = invoice?.invoiceNumber?.toString();
        return (
          prefix.includes(searchTerm) ||
          number.includes(searchTerm) ||
          searchTerm.includes(prefix + number)
        );
      });
      setTotalInvoices(filteredInvoices.length);
      setInvoiceList(filteredInvoices);
    } else {
      setTotalInvoices(allInvoiceList.length);
      setInvoiceList(allInvoiceList);
    }
  }, [props.searchInvoice]);

  useEffect(() => {
    setLoader(true);
    const id: any = localStorage.getItem("userID");
    const offset = page * rowsPerPage;
    BookingApi.getOnlineUserInvoices(id, offset, rowsPerPage).then((e) => {
      setTotalInvoices(e.data.total);
      setInvoiceList(e.data.invoiceList);
      setAllInvoiceList(e.data.invoiceList);
      setLoader(false);
    });
  }, [page, rowsPerPage]);
  return (
    <>
      <AlertMessage
        openModal={openModal}
        requestOnClose={requestOnClose}
        modalMessage={modalMessage}
      />
      {!isSmallScreen && (
        <>
          {loader ? (
            <Typography className="!text-center !text-3xl !flex !justify-center">
              Loading...
            </Typography>
          ) : (
            <div>
              {invoiceList.length === 0 ? (
                <DefaultPage
                  message={"You haven't created any invoices yet."}
                ></DefaultPage>
              ) : (
                <>
                  <TableContainer className="!shadow-grey-400 !rounded-2xl !border-solid !border-3 p-4">
                    <Table
                      className="!shadow-2xl rounded-2xl !border-solid !border-2 !border-grey-400"
                      aria-label="simple table"
                      stickyHeader
                    >
                      <TableHead className="!bg-[primary] rounded-2xl">
                        <TableRow className="!bg-[#dc1c24] rounded-2xl">
                          <TableCell
                            className="!text-[15px] rounded-tl-2xl"
                            style={{
                              backgroundColor:
                                dynamicTheme?.palette?.["primary"]?.main,
                              color:
                                dynamicTheme?.palette?.["primary"]
                                  ?.contrastText,
                            }}
                          >
                            {t("Invoice Number")}
                          </TableCell>
                          <TableCell
                            className="!text-[15px]"
                            style={{
                              backgroundColor:
                                dynamicTheme?.palette?.["primary"]?.main,
                              color:
                                dynamicTheme?.palette?.["primary"]
                                  ?.contrastText,
                            }}
                          >
                            {t("Start Date")}
                          </TableCell>
                          <TableCell
                            className="!text-[15px]"
                            style={{
                              backgroundColor:
                                dynamicTheme?.palette?.["primary"]?.main,
                              color:
                                dynamicTheme?.palette?.["primary"]
                                  ?.contrastText,
                            }}
                          >
                            {t("End Date")}
                          </TableCell>
                          <TableCell
                            className="!text-[15px]"
                            style={{
                              backgroundColor:
                                dynamicTheme?.palette?.["primary"]?.main,
                              color:
                                dynamicTheme?.palette?.["primary"]
                                  ?.contrastText,
                            }}
                          >
                            {t("Invoice Amount")}
                          </TableCell>
                          <TableCell
                            className="!text-[15px]"
                            style={{
                              backgroundColor:
                                dynamicTheme?.palette?.["primary"]?.main,
                              color:
                                dynamicTheme?.palette?.["primary"]
                                  ?.contrastText,
                            }}
                          >
                            {t("Status")}
                          </TableCell>
                          <TableCell
                            className="text-[15px] rounded-tr-2xl"
                            style={{
                              backgroundColor:
                                dynamicTheme?.palette?.["primary"]?.main,
                              color:
                                dynamicTheme?.palette?.["primary"]
                                  ?.contrastText,
                            }}
                          >
                            {t("Download")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoiceList.map((e: any, i) => (
                          <TableRow key={i}>
                            <TableCell
                              className="!text-[12px] text-[#333] rounded-bl-2xl"
                              component="th"
                              scope="e"
                            >
                              <ul>
                                <li>
                                  {e.prefix + "-" + e.invoiceNumber || ""}
                                </li>
                                <li className="text-[#838181] text-[10px]">
                                  {e.rentalAgreement != null &&
                                  e.rentalAgreement != ""
                                    ? "Rental" + " " + e.rentalAgreement
                                    : "Reservation" + " " + e.reservation}
                                </li>
                              </ul>
                            </TableCell>
                            <TableCell className="!text-[12px] text-[#333]">
                              {e.startDate || ""}
                            </TableCell>
                            <TableCell className="!text-[12px] text-[#333]">
                              {e.invoiceEndDate || ""}
                            </TableCell>
                            <TableCell className="!text-[12px] text-[#333]">
                              {"€" + " " + e.totInvoice || ""}
                            </TableCell>
                            <TableCell>
                              <div
                                className="!text-[11px] text-[#333]"
                                style={{
                                  backgroundColor: e.isPaid
                                    ? "lightgreen"
                                    : "#FF634748",
                                  color: e.isPaid ? "#008741" : "primary",
                                  textAlign: "center",
                                  borderRadius: "1rem",
                                  fontWeight: "bold",
                                  padding: "2px",
                                }}
                              >
                                {e.isPaid ? "Paid" : "Unpaid"}
                              </div>
                            </TableCell>
                            <TableCell>
                              <button
                                className="flex text-[14px] h-70 text-[#FF0000] rounded"
                                onClick={() => downloadInvoice(e.id)}
                              >
                                Invoice
                                <img
                                  className="h-3"
                                  style={{ marginLeft: "5px" }}
                                  src="/assets/images/downloadIcon.png"
                                  alt="Invoice Icon"
                                />
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[8]}
                    component="div"
                    count={totalInvoices}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPage}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}

      {isSmallScreen && (
        <>
          {loader ? (
            <Typography className="!text-center !text-3xl !flex !justify-center">
              Loading...
            </Typography>
          ) : (
            <div>
              {invoiceList.length === 0 ? (
                <DefaultPage
                  message={"You haven't created any invoices yet."}
                ></DefaultPage>
              ) : (
                <>
                  <Grid container spacing={2}>
                    {invoiceList.map((e: any, i) => (
                      <Grid item xs={12} sm={6} md={4} key={i}>
                        <Card
                          className="!shadow-2xl rounded-2xl !border-solid !border-2 !border-grey-400 w-full ml-5 whitespace-nowrap"
                          style={{ borderRadius: "10px 10px 10px 10px" }}
                        >
                          <CardHeader
                            title={t("Invoice Id:")}
                            style={{
                              color:
                                dynamicTheme?.palette?.["primary"]
                                  ?.contrastText,
                              backgroundColor:
                                dynamicTheme?.palette?.["primary"]?.main,
                              height: "30px",
                              padding: "8px 16px",
                              lineHeight: "1.5",
                              borderRadius: "10px 10px 0px 0px",
                            }}
                            titleTypographyProps={{
                              style: {
                                color: "white",
                              },
                            }}
                          />
                          <CardContent>
                            <Typography
                              className="!text-[15px]"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span> {t("Invoice Number")}</span>
                              <span>
                                {" "}
                                {e.prefix}-{e.invoiceNumber}
                              </span>
                            </Typography>
                            <hr />
                            <Typography
                              className="!text-[15px]"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span> {t("Start Date")}</span>
                              <span>{e.startDate}</span>
                            </Typography>
                            <hr />
                            <Typography
                              className="!text-[15px]"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span> {t("End Date")}</span>
                              <span>{e.invoiceEndDate}</span>
                            </Typography>
                            <hr />
                            <Typography
                              className="!text-[15px]"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>{t("Invoice Amount")}</span>
                              <span>€ {e.totInvoice}</span>
                            </Typography>
                            <hr />
                            <Typography
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                className="!text-[15px]"
                                style={{
                                  backgroundColor: e.isPaid
                                    ? "lightgreen"
                                    : "#FF634748",
                                  color: e.isPaid ? "#008741" : "primary",
                                  textAlign: "center",
                                  borderRadius: "1rem",
                                  fontWeight: "bold",
                                  width: "50%",
                                  marginTop: "1%",
                                }}
                              >
                                {e.isPaid ? "Paid" : "Unpaid"}
                              </span>
                              <span>
                                <button
                                  className="flex text-[14px] h-70 text-[#FF0000] rounded mt-2"
                                  onClick={() => downloadInvoice(e.id)}
                                >
                                  {t("Download")}{" "}
                                  <img
                                    className="h-3 ml-2"
                                    src="/assets/images/downloadIcon.png"
                                    alt="Invoice Icon"
                                  />
                                </button>
                              </span>
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
