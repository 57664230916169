import React, { useEffect, useState } from "react";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import { BookingApi } from "../../API/Apis/BookingApi";
import NotAuthorisedPage from "../notAuthorisedPage";
import InvoiceCard from "./InvoiceCard";
import { t } from "i18next";
import { AppStandardButton } from "../../components/UI/AppButtons";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import BookingGetters from "../../store/onlineBooking/getters";
import CollapsibleNavbar from "../../components/Layouts/ReservedAreaDashboard/CollapseNavbar";

export default function MyInvoices() {
  const AppSettings = BookingGetters.getAppSettings();
  const [showInvoices, setShowInvoices] = useState(false);
  useEffect(() => {
    setShowInvoices(!!AppSettings?.reservedArea);
  }, [AppSettings]);
  const [searchInvoice, setSearchInvoice] = useState("");
  const [finalSearchInvoice, setFinalSearchInvoice] = useState("");
  const handleSearchInvoice = () => {
    setFinalSearchInvoice(searchInvoice);
  };
  const setSearchInvoiceValue = (event) => {
    setSearchInvoice(event.target.value);
  };
  const isSmallScreen = window.innerWidth <= 768;
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;
  return (
    <div>
      {showInvoices && (
        <div className="flex-row md:flex">
          {!isSmallScreen && !istabletScreen && (
            <div className="!w-780" style={{ width: "300px" }}>
              <BookingDetalisListSideNavigation />
            </div>
          )}
          {(isSmallScreen || istabletScreen) && (
            <CollapsibleNavbar callFrom="showReservations" />
          )}
          <div
            className="rounded-sm pt-0 md:pt-12"
            style={
              istabletScreen
                ? { width: "100%" }
                : isSmallScreen
                ? { width: "90%" }
                : { width: "75%" }
            }
          >
            <div className="text-[#333] px-8 max-w-screen-xl m-auto">
              <div className="flex justify-between items-center">
                <p className="text-[24px] font-medium leading-snug whitespace-nowrap">
                  {t("Invoice List")}
                </p>
                <div className="flex items-center">
                  {!isSmallScreen && (
                    <TextField
                      label={t("Search Invoice")}
                      variant="outlined"
                      className="mr-4"
                      value={searchInvoice}
                      onChange={setSearchInvoiceValue}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            onClick={handleSearchInvoice}
                            style={{ cursor: "pointer" }}
                          >
                            <Search />
                          </InputAdornment>
                        ),
                        style: {
                          height: "40px",
                          borderRadius: "10px",
                          paddingRight: "5px",
                        },
                      }}
                    />
                  )}

                  {!isSmallScreen && (
                    <AppStandardButton
                      href="/"
                      className="ml-4"
                      variant="contained"
                      style={{
                        width: "150px",
                        backgroundColor: "primary",
                        borderRadius: "10px",
                      }}
                    >
                      <span className="normal-case">{t("Book a Car")}</span>
                    </AppStandardButton>
                  )}
                </div>
              </div>
              <p className="text-[#838181] text-[16px]">
                {t("View all your Invoices here.")}
              </p>
            </div>

            {isSmallScreen && (
              <div className="ml-8 mt-4">
                <TextField
                  label={t("Search Invoice")}
                  variant="outlined"
                  value={searchInvoice}
                  onChange={setSearchInvoiceValue}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={handleSearchInvoice}
                        style={{ cursor: "pointer" }}
                      >
                        <Search />
                      </InputAdornment>
                    ),
                    style: {
                      height: "40px",
                      borderRadius: "10px",
                      paddingRight: "5px",
                    },
                  }}
                />
              </div>
            )}

            <div className="block p-3">
              <InvoiceCard searchInvoice={finalSearchInvoice} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
