import React, { useState } from "react";
import { formatTheme } from "../../config/MuiColorThemes";
import { createTheme } from "@mui/material/styles";

const Collapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const toggleOpen = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible-container">
      <div className="collapsible-header" onClick={toggleOpen}>
        <h3>{title}</h3>
        <button
          type="button"
          className="toggle-button"
          style={{
            backgroundColor: dynamicTheme?.palette["primary"]?.main,
          }}
        >
          {isOpen ? "-" : "+"}
        </button>
      </div>
      {isOpen && <div className="collapsible-content">{children}</div>}
    </div>
  );
};

export default Collapsible;
