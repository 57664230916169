import React, { useEffect, useState } from "react";
import {
  DefaultValuesDetailsForm,
  DetailsFormValues,
  GetReservationByReservationVoucher,
} from "../../Layouts/WebCheckInForms/WebCheckInForms.props";
import { AppInputSwitcher } from "../../Layouts/AppInputSwitcher/AppInputSwitcher";
import { WebCheckInFormFields } from "../../Layouts/WebCheckInForms/FormOptions";
import { useForm } from "react-hook-form";
import { FormFieldErrorMessage } from "../FormFieldErrorMessage/FormFieldErrorMessage";
import { useTranslation } from "react-i18next";

export function ByVoucherNumberForm({
  values = {},
  onChange = () => {},
  onSubmit = () => {},
  children = <></>,
}: GetReservationByReservationVoucher): JSX.Element {
  const { t: translate } = useTranslation();
  const [currentValues, setCurrentValues] = useState<DetailsFormValues>({
    ...DefaultValuesDetailsForm,
    ...values,
  });
  const {
    formState: { errors },
    setValue,
    handleSubmit,
    getValues,
    register,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });
  useEffect(() => {
    onChange(currentValues);
  }, [currentValues]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col gap-2"}>
      <AppInputSwitcher
        {...WebCheckInFormFields.voucherCode}
        {...register("reservationVoucher", {
          required: {
            value: true,
            message: "Please provide a valid voucher code",
          },
        })}
        required={false}
        value={getValues("reservationVoucher") || ""}
        label={translate("VoucherCodeWebcheckin")}
        onChange={() => {}}
        onchange={(e: string) => {
          setValue("reservationVoucher", e);
          setCurrentValues((prev) => ({
            ...prev,
            reservationVoucher: e,
          }));
        }}
        className={"h-20"}
      />
      <FormFieldErrorMessage>
        {errors?.reservationVoucher?.message}
      </FormFieldErrorMessage>
      <div className={"flex justify-center items-center "}>
        <hr className={"grow  border-red-600 "} />
        <span className={"h-full p-4 font-semibold  "}>
          Choose any one from the below
        </span>
        <hr className={"grow  border-red-600 "} />
      </div>
      <FormFieldErrorMessage>
        {errors.firstName &&
          errors.lastname &&
          errors.companyName &&
          "Provide First Name or Last Name or Company Name"}
      </FormFieldErrorMessage>
      <AppInputSwitcher
        {...register("firstName", {
          required: {
            value: !currentValues.companyName && !currentValues.firstName,
            message: "Please provide First Name",
          },
        })}
        value={getValues("firstName") || ""}
        onChange={() => {}}
        required={false}
        label={translate("First Name")}
        type="text"
        key={"firstName"}
        onchange={(e) => {
          setValue("firstName", e);
          setCurrentValues((prev: DetailsFormValues) => ({
            ...prev,
            firstName: e,
          }));
        }}
        className={"h-20 my-2"}
        options={[]}
      />
      <FormFieldErrorMessage>{errors?.lastName?.message}</FormFieldErrorMessage>

      <AppInputSwitcher
        {...register("lastName", {
          required: {
            value: !!currentValues.lastName,
            message: "Please provide last name",
          },
        })}
        required={false}
        onChange={() => {}}
        label={translate("Last Name")}
        type="text"
        value={currentValues.lastName}
        key={"lastName"}
        onchange={(e) => {
          setValue("lastName", e);
          setCurrentValues((prev: DetailsFormValues) => ({
            ...prev,
            lastName: e,
          }));
        }}
        className={"h-20"}
        options={[]}
      />
      <FormFieldErrorMessage>{errors?.lastName?.message}</FormFieldErrorMessage>
      <div className={"flex justify-center items-center py-4"}>
        <span
          className={
            "h-fit bg-[primary] rounded-full aspect-square text-white align-middle flex items-center justify-center px-2 text-[#000000]"
          }
        >
          OR
        </span>
      </div>

      <AppInputSwitcher
        {...register("companyName", {
          required: {
            value: !currentValues.firstName && !currentValues.lastName,
            message: "Please provide company name",
          },
        })}
        onChange={() => {}}
        required={false}
        className={"h-20"}
        type="text"
        variant="material"
        label={translate("Company Name")}
        placeholder="company Name"
        value={currentValues.companyName}
        onchange={(e) => {
          setCurrentValues((prev: DetailsFormValues) => ({
            ...prev,
            companyName: e,
          }));
        }}
        options={[]}
        key={"companyName"}
      />
      <FormFieldErrorMessage>
        {errors?.companyName?.message}
      </FormFieldErrorMessage>

      {children}
    </form>
  );
}
