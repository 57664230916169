import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Modal } from ".";
import { AppStandardButton } from "../AppButtons";
import { useTranslation } from "react-i18next";

export default function AlertDialog({
  open,
  onClose,
  onCancel,
  onConfirm,
  title,
}) {
  const { t: translate } = useTranslation();
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogActions>
          <AppStandardButton variant="outlined" onClick={onCancel}>
            <p className="text-black normal-case">{translate("Close1")}</p>
          </AppStandardButton>
          <AppStandardButton onClick={onConfirm} autoFocus>
            <p className="normal-case">{translate("Add Driver")}</p>
          </AppStandardButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
