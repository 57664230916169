import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AppCards from "../../components/UI/AppCards";
import React, { useEffect, useState } from "react";
import BookingApi from "../../API/Apis/BookingApi";
import { Navigate, useNavigate } from "react-router-dom";
import AppButton from "../../components/UI/AppButtons";
import { formatTheme } from "../../config/MuiColorThemes";
import { createTheme } from "@mui/material/styles";
import BookingGetters from "../../store/onlineBooking/getters";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState([]);
  const isDesktopScreen = window.innerWidth >= 1310;
  const [loading, setLoading] = useState(false);
  const blurScreen = () => {
    document.body.style.opacity = String(0.5);
    document.body.style.pointerEvents = "none";
  };
  useEffect(() => {
    if (loading) {
      blurScreen();
    } else {
      document.body.style.opacity = String(1);
      document.body.style.pointerEvents = "";
    }
  }, [loading]);
  return (
    <div className="flex flex-col items-center min-h-[60vh] justify-center">
      <form
        className="w-full md:w-1/2"
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          BookingApi.sentVerificationcode(email)
            .then((e) => {
              setLoading(false);
              if (e.status == "success") {
                // alert("Email Sent Sucessfully");
                navigate("/updatePassword");
              } else {
                alert("Email is not registered");
              }
              return e.data;
            })
            .catch((e) => {
              return e;
            });
        }}
      >
        <AppCards className="box-border p-4">
          <header className="flex flex-col gap-2 box-border py-12">
            <h2 className="w-full  text-2xl font-semibold">
              Forgot your Password ?
            </h2>
            <p>
              A link to reset your password will be sent to the registered Email
              address
            </p>
          </header>

          <Grid item xs={12} sm={6}>
            <AppInputSwitcher
              required={true}
              className={"w-full"}
              containerheight={"53px"}
              containerwidth={!isDesktopScreen ? "340px" : "421px"}
              value={email}
              type="text"
              label="Email Address"
              key="email"
              showlabel={true}
              onchange={(v) => {
                setEmail(v);
              }}
              options={[]}
              variant="material"
            />
            <AppButton
              type={"submit"}
              color={"primary"}
              className={` ${isDesktopScreen ? "w-[57%]" : "w-[100%]"}`}
              style={{ marginTop: "31px" }}
            >
              <p className={`w-full text-center`}>Submit</p>
            </AppButton>
          </Grid>
        </AppCards>
      </form>
    </div>
  );
}
