import React, { memo, useEffect, useState } from "react";
import BookingApi from "../../../API/Apis/BookingApi";
import { t } from "i18next";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import AppStandardButton from "../../UI/AppButtons";
import TextField from "@mui/material/TextField";
import DefaultPage from "../DefaultPage";
import { createTheme, Theme } from "@mui/material/styles";
import { formatThemeForReservedArea } from "../../../config/MuiColorThemes";

function ReservationCard() {
  const [noOfBooking, setNoOfBooking] = useState<number>(0);
  const [reservationList, setReservationList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [invLoader, setInvLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [bookingCancelReason, setBookingCancelReason] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [prefix, setPrefix] = useState("");
  const [codice, setCodice] = useState("");
  const [number, setNumber] = useState("");
  const [reservationSourceCode, setReservationSourceCode] = useState("");
  const isSmallScreen = window.innerWidth <= 768;
  const useStyles = makeStyles((theme) => ({
    customTableCell: {
      width: "150px",
    },
    customTableCel2: {
      width: "20px",
    },
  }));
  const [dynamicTheme, setDynamicTheme] = useState<Theme | null>(null);

  useEffect(() => {
    async function fetchTheme() {
      const dynamicPalette = await formatThemeForReservedArea();
      const theme = createTheme(dynamicPalette);
      setDynamicTheme(theme);
    }
    fetchTheme();
  }, []);
  const classes = useStyles();
  const blurScreen = () => {
    document.body.style.opacity = String(0.5);
    document.body.style.pointerEvents = "none";
  };
  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    if (loader || invLoader) {
      blurScreen();
    } else {
      document.body.style.opacity = String(1);
      document.body.style.pointerEvents = "";
    }
  }, [loader, invLoader]);

  useEffect(() => {
    setLoader(true);
    const id: any = localStorage.getItem("userID");
    const offset = page * rowsPerPage;
    BookingApi.getBookingDetailsbyUserId(id, offset, rowsPerPage).then((e) => {
      setNoOfBooking(e.data.total);
      setReservationList(e.data.reservationList);
      setLoader(false);
    });
  }, [page, rowsPerPage]);

  const handleBookingCancelReason = (event) => {
    setBookingCancelReason(event.target.value);
  };

  const cancelBooking = (prefix, number, codice, chanel) => {
    setPrefix(prefix);
    setCodice(codice);
    setNumber(number);
    setReservationSourceCode(chanel);
    setModalMessage("Please provide the reason for canceling your booking");
    setMessageType("CancelBooking");
    setOpenModal(true);
  };

  const requestBookingCancel = () => {
    if (bookingCancelReason == "") {
      setErrorMessage("** Reason is mandatory **");
    } else {
      setMessageType("");
      setModalMessage("");
      setOpenModal(false);
      BookingApi.cancelBooking(
        prefix,
        number,
        codice,
        bookingCancelReason,
        reservationSourceCode
      )
        .then((e) => {
          const status = e.data.CancelStatus;
          if (status === "Cancelled") {
            setModalMessage("Booking Cancelled");
            setOpenModal(true);
          } else {
            setModalMessage("Booking not canceled");
            setOpenModal(true);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const onRequestClose = () => {
    setMessageType("");
    setModalMessage("");
    setErrorMessage("");
    setOpenModal(false);
    setBookingCancelReason("");
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={onRequestClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle>{modalMessage}</DialogTitle>

        <DialogActions>
          <Grid container item xs={12}>
            {messageType == "CancelBooking" && (
              <Grid item xs={12}>
                <TextField
                  id="outlined-multiline-flexible"
                  multiline
                  rows={5}
                  variant="outlined"
                  fullWidth
                  style={{ padding: "1rem" }}
                  onChange={handleBookingCancelReason}
                  value={bookingCancelReason}
                />
                <span
                  style={{
                    textAlign: "center",
                    color: "red",
                    display: "block",
                    fontStyle: "italic",
                  }}
                >
                  {errorMessage}
                </span>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                {messageType == "CancelBooking" && (
                  <Grid item style={{ marginRight: "15px" }}>
                    <AppStandardButton
                      variant="contained"
                      onClick={requestBookingCancel}
                      style={{ backgroundColor: "primary" }}
                    >
                      <p className="text-light normal-case">Cancel Booking</p>
                    </AppStandardButton>
                  </Grid>
                )}
                <Grid item>
                  <AppStandardButton
                    variant="outlined"
                    onClick={onRequestClose}
                  >
                    <p className="text-black normal-case">Close</p>
                  </AppStandardButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {!isSmallScreen &&
        (loader ? (
          <Typography className="!text-center !text-3xl flex justify-center">
            Loading...
          </Typography>
        ) : (
          <div className="w-[100%] rounded-sm">
            {reservationList.length === 0 ? (
              <DefaultPage
                message={
                  "You have not created any booking yet. To create a new booking, please click on the Book a car button."
                }
              />
            ) : (
              <>
                <TableContainer>
                  <Table
                    className="!border-2 rounded-2xl"
                    aria-label="simple table"
                    stickyHeader
                    style={{ fontFamily: "MBCorpoAScreen1Cond-Regular" }}
                  >
                    <TableHead className="!bg-[#dc1c24]">
                      <TableRow className="rounded-2xl">
                        <TableCell
                          className={`!text-[14px] rounded-tl-2xl ${classes.customTableCell}`}
                          style={{
                            backgroundColor:
                              dynamicTheme?.palette?.["primary"]?.main,
                            color:
                              dynamicTheme?.palette?.["primary"]?.contrastText,
                          }}
                        >
                          {t("Customer")}
                        </TableCell>
                        <TableCell
                          className={`!text-[14px] ${classes.customTableCell}`}
                          style={{
                            backgroundColor:
                              dynamicTheme?.palette?.["primary"]?.main,
                            color:
                              dynamicTheme?.palette?.["primary"]?.contrastText,
                          }}
                        >
                          {t("Pick up location")}
                        </TableCell>
                        <TableCell
                          className={`!text-[14px] ${classes.customTableCell}`}
                          style={{
                            backgroundColor:
                              dynamicTheme?.palette?.["primary"]?.main,
                            color:
                              dynamicTheme?.palette?.["primary"]?.contrastText,
                          }}
                        >
                          {t("Drop off location")}
                        </TableCell>
                        <TableCell
                          className={`!text-[14px] ${classes.customTableCell}`}
                          style={{
                            backgroundColor:
                              dynamicTheme?.palette?.["primary"]?.main,
                            color:
                              dynamicTheme?.palette?.["primary"]?.contrastText,
                          }}
                        >
                          {t("Date")}
                        </TableCell>
                        <TableCell
                          className={`!text-[14px] ${classes.customTableCell}`}
                          style={{
                            backgroundColor:
                              dynamicTheme?.palette?.["primary"]?.main,
                            color:
                              dynamicTheme?.palette?.["primary"]?.contrastText,
                          }}
                        >
                          {t("Group")}
                        </TableCell>
                        <TableCell
                          className={`!text-[14px] ${classes.customTableCell}`}
                          style={{
                            backgroundColor:
                              dynamicTheme?.palette?.["primary"]?.main,
                            color:
                              dynamicTheme?.palette?.["primary"]?.contrastText,
                          }}
                        >
                          {t("Total Amount")}
                        </TableCell>
                        <TableCell
                          className={`!text-[14px] ${classes.customTableCel2}`}
                          style={{
                            backgroundColor:
                              dynamicTheme?.palette?.["primary"]?.main,
                            color:
                              dynamicTheme?.palette?.["primary"]?.contrastText,
                          }}
                        >
                          {t("Status")}
                        </TableCell>
                        <TableCell
                          className={`!text-[14px] rounded-tr-2xl ${classes.customTableCel2}`}
                          style={{
                            backgroundColor:
                              dynamicTheme?.palette?.["primary"]?.main,
                            color:
                              dynamicTheme?.palette?.["primary"]?.contrastText,
                          }}
                        >
                          {t("Action")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reservationList.map((e: any, i) => (
                        <TableRow key={i}>
                          <TableCell
                            className="!text-[12px] text-[#333]"
                            component="th"
                            scope="e"
                          >
                            <ul>
                              <li className="!text-[#333]">
                                {e.clientiByIdCliente.description
                                  .split("/")[0]
                                  .split("-")
                                  .reverse()
                                  .join(" ") || ""}
                              </li>
                              <li className="text-[#838181] text-[10px]">
                                {e.clientiByIdCliente.email || ""}
                              </li>
                            </ul>
                          </TableCell>
                          <TableCell className="!text-[12px]">
                            <ul>
                              <li>
                                {e.locationPickupExcepted.description.split(
                                  "-"
                                )[0] || ""}
                              </li>
                              <li className="text-[#838181] text-[10px]">
                                {e.startDate.slice(11, 19) || ""}
                              </li>
                            </ul>
                          </TableCell>
                          <TableCell className="!text-[12px]">
                            <ul>
                              <li>
                                {e.locationReturnExcepted.description.split(
                                  "-"
                                )[0] || ""}
                              </li>
                              <li className="text-[#838181] text-[10px]">
                                {e.endDate.slice(11, 19) || ""}
                              </li>
                            </ul>
                          </TableCell>
                          <TableCell className="!text-[12px]">
                            {`${String(
                              new Date(e.startDate).getDate()
                            ).padStart(2, "0")}-${String(
                              new Date(e.startDate).getMonth() + 1
                            ).padStart(2, "0")}-${new Date(
                              e.startDate
                            ).getFullYear()}`}{" "}
                            -{" "}
                            {`${String(new Date(e.endDate).getDate()).padStart(
                              2,
                              "0"
                            )}-${String(
                              new Date(e.endDate).getMonth() + 1
                            ).padStart(2, "0")}-${new Date(
                              e.endDate
                            ).getFullYear()}`}
                          </TableCell>
                          <TableCell className="!text-[12px]">
                            {e.assignedGroup.description || ""}
                          </TableCell>
                          <TableCell className="!text-[12px]">
                            {"€" + " " + e.totalRental || ""}
                          </TableCell>
                          <TableCell className="!text-[12px]">
                            {e.isConfirmed === true ? (
                              <div
                                style={{
                                  backgroundColor: "#E8FFF3",
                                  color: "#008741",
                                  fontSize: "bold",
                                  borderRadius: "8px",
                                  padding: "3px",
                                  textAlign: "center",
                                }}
                              >
                                <p>Confirm</p>
                              </div>
                            ) : e.isConfirmed === false &&
                              e.isCancel === false ? (
                              <div
                                style={{
                                  backgroundColor: "#FFFFE8",
                                  color: "#FFD500",
                                  fontSize: "bold",
                                  borderRadius: "8px",
                                  padding: "3px",
                                  textAlign: "center",
                                }}
                              >
                                <p>Pending</p>
                              </div>
                            ) : (
                              <div
                                style={{
                                  backgroundColor: "#FFE8EA",
                                  color: "primary",
                                  fontSize: "bold",
                                  borderRadius: "8px",
                                  padding: "3px",
                                  textAlign: "center",
                                }}
                              >
                                <p>Cancel</p>
                              </div>
                            )}
                          </TableCell>
                          <TableCell className="!text-[12px]">
                            <button
                              disabled={e.isCancel === true}
                              className={`flex text-[12px] p-2 gap-2 h-30 rounded shadow`}
                              style={
                                e.isCancel === true
                                  ? {
                                      backgroundColor: "#F0F0F0",
                                      color: "#D7D7D7",
                                    }
                                  : {
                                      backgroundColor:
                                        dynamicTheme?.palette?.[
                                          "secondaryColor"
                                        ]?.main,
                                      color:
                                        dynamicTheme?.palette?.[
                                          "secondaryColor"
                                        ]?.contrastText,
                                    }
                              }
                              onClick={() =>
                                cancelBooking(
                                  e.prefix,
                                  e.number,
                                  e.codice,
                                  e.reservationSourceCode
                                )
                              }
                            >
                              Cancel
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[7]}
                  component="div"
                  count={noOfBooking}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPage}
                />
              </>
            )}
          </div>
        ))}

      {isSmallScreen &&
        (loader ? (
          <Typography className="!text-center !text-3xl flex justify-center">
            Loading...
          </Typography>
        ) : (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {reservationList.length === 0 ? (
              <DefaultPage
                message={
                  "You have not created any booking yet. To create a new booking, please click on the Book a car button."
                }
              />
            ) : (
              <>
                <Grid container spacing={2}>
                  {reservationList.map((e: any, i) => (
                    <Grid item xs={12} sm={6} md={4} key={i}>
                      <Card
                        className="!shadow-2xl rounded-2xl !border-solid !border-2 !border-grey-400 w-full ml-5 whitespace-nowrap"
                        style={{ borderRadius: "10px 10px 10px 10px" }}
                      >
                        <CardHeader
                          title={`${e.prefix || ""} ${e.number || ""}`}
                          style={{
                            color: "white",
                            backgroundColor:
                              dynamicTheme?.palette?.["primary"]?.main,
                            height: "40px",
                            padding: "8px 16px",
                            lineHeight: "1.5",
                            borderRadius: "10px 10px 0px 0px",
                          }}
                          titleTypographyProps={{
                            style: {
                              color:
                                dynamicTheme?.palette?.["primary"]
                                  ?.contrastText,
                            },
                          }}
                        />
                        <CardContent>
                          <Typography
                            className="!text-[15px]"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>{t("Customer")}</span>
                            <span>
                              {e.clientiByIdCliente.description
                                .split("/")[0]
                                .split("-")
                                .reverse()
                                .join(" ") || ""}
                            </span>
                          </Typography>
                          <hr />
                          <Typography
                            className="!text-[15px]"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span> {t("Email")}</span>
                            <span>{e.clientiByIdCliente.email || ""}</span>
                          </Typography>
                          <hr />
                          <Typography
                            className="!text-[15px]"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span> {t("Pick up location")}</span>
                            <span>
                              {`${
                                e.locationPickupExcepted.description.split(
                                  "-"
                                )[0] || ""
                              } ${e.startDate.slice(11, 19) || ""}`}
                            </span>
                          </Typography>
                          <hr />

                          <Typography
                            className="!text-[15px]"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span> {t("Drop off location")}</span>
                            <span>
                              {`${
                                e.locationReturnExcepted.description.split(
                                  "-"
                                )[0] || ""
                              } ${e.endDate.slice(11, 19) || ""}`}
                            </span>
                          </Typography>
                          <hr />
                          <Typography
                            className="!text-[15px]"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span> {t("Date")}</span>
                            <span>
                              {" "}
                              {`${String(
                                new Date(e.startDate).getDate()
                              ).padStart(2, "0")}-${String(
                                new Date(e.startDate).getMonth() + 1
                              ).padStart(2, "0")}-${new Date(
                                e.startDate
                              ).getFullYear()}`}{" "}
                              -{" "}
                              {`${String(
                                new Date(e.endDate).getDate()
                              ).padStart(2, "0")}-${String(
                                new Date(e.endDate).getMonth() + 1
                              ).padStart(2, "0")}-${new Date(
                                e.endDate
                              ).getFullYear()}`}
                            </span>
                          </Typography>
                          <hr />

                          <Typography
                            className="!text-[15px]"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>{t("Group")}</span>
                            <span>{e.assignedGroup.description || ""}</span>
                          </Typography>
                          <hr />

                          <Typography
                            className="!text-[15px]"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span> {t("Total Amount")}</span>
                            <span> {"€" + " " + e.totalRental || ""}</span>
                          </Typography>
                          <hr />

                          <Typography
                            className="!text-[15px]"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="mt-1">{t("Status")}</span>
                            <span>
                              <div className="mt-1">
                                {e.isConfirmed === true ? (
                                  <div className="bg-green-200 text-green-700 font-bold rounded-lg px-3 py-1">
                                    <p>Confirm</p>
                                  </div>
                                ) : e.isConfirmed === false &&
                                  e.isCancel === false ? (
                                  <div className="bg-yellow-200 text-yellow-700 font-bold rounded-lg px-3 py-1">
                                    <p>Pending</p>
                                  </div>
                                ) : (
                                  <div className="bg-red-200 text-red-700 font-bold rounded-lg px-3 py-1">
                                    <p>Cancel</p>
                                  </div>
                                )}
                              </div>
                            </span>
                            <span className="mt-1">
                              <Button
                                style={{
                                  backgroundColor:
                                    dynamicTheme?.palette?.["primary"]?.main,
                                  color:
                                    dynamicTheme?.palette?.["primary"]
                                      ?.contrastText,
                                }}
                                disabled={e.isCancel === true}
                                className="text-sm rounded-lg text-white px-2 py-0"
                                onClick={() =>
                                  cancelBooking(
                                    e.prefix,
                                    e.number,
                                    e.codice,
                                    e.reservationSourceCode
                                  )
                                }
                              >
                                Cancel
                              </Button>
                            </span>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </div>
        ))}
    </>
  );
}
export default memo(ReservationCard);
