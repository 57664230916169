import { WebCheckInCombinedForm, WebCheckInFormFields } from "./FormOptions";
import { AppInputSwitchProps } from "../AppInputSwitcher/AppInputSwitcher.props";

export type WebCheckFormType = Record<
  keyof WebCheckInCombinedForm,
  AppInputSwitchProps
>;
export const SelfDriverFormFields: Record<
  | "documents"
  | "documentNumber"
  | "licenseType"
  | "releaseDate"
  | "expiryDate"
  | "issuedBy",
  any
> = {
  documents: {
    ...WebCheckInFormFields.documents,
    key: "document",
    placeholder: "Document",
  },
  documentNumber: WebCheckInFormFields.documentNumber,
  licenseType: WebCheckInFormFields.licenseType,
  releaseDate: WebCheckInFormFields.releaseDate,
  expiryDate: WebCheckInFormFields.expiryDate,
  issuedBy: {
    ...WebCheckInFormFields.issuedBy,
  },
};
export const IndividualForm: WebCheckFormType = {
  ...WebCheckInFormFields,
};
export const DriverFormFields = {
  firstName: WebCheckInFormFields.firstName,
  middleName: WebCheckInFormFields.middleName,
  lastName: WebCheckInFormFields.lastName,
  country: WebCheckInFormFields.country,
  city: WebCheckInFormFields.city,
  state: WebCheckInFormFields.state,
  zip: WebCheckInFormFields.zip,
  streetName: WebCheckInFormFields.streetName,
  n: WebCheckInFormFields.n,
  mobileNumber: WebCheckInFormFields.mobileNumber,
  email: WebCheckInFormFields.email,
  gender: WebCheckInFormFields.gender,
  birthNation: WebCheckInFormFields.birthNation,
  birthDate: WebCheckInFormFields.birthDate,
  birthPlace: WebCheckInFormFields.birthPlace,
  birthProv: WebCheckInFormFields.birthProv,
  ssn: WebCheckInFormFields.ssn,
  documents: WebCheckInFormFields.documents,
  documentNumber: WebCheckInFormFields.documentNumber,
  licenseType: WebCheckInFormFields.licenseType,
  releaseDate: WebCheckInFormFields.releaseDate,
  expiryDate: WebCheckInFormFields.expiryDate,
  issuedBy: {
    ...WebCheckInFormFields.issuedBy,
    key: "issueBy",
  },
};
