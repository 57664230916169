import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BookingApi from "../../API/Apis/BookingApi";
import NotAuthorisedPage from "../notAuthorisedPage";
import { BookingGetters } from "../../store/onlineBooking/getters";
import { t } from "i18next";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { AppStandardButton } from "../../components/UI/AppButtons";
import { createTheme, Theme } from "@mui/material/styles";
import { formatThemeForReservedArea } from "../../config/MuiColorThemes";
import CollapsibleNavbar from "../../components/Layouts/ReservedAreaDashboard/CollapseNavbar";

export function Profile() {
  const appSettings = BookingGetters?.getAppSettings();
  const [dynamicTheme, setDynamicTheme] = useState<Theme | null>(null);
  useEffect(() => {
    async function fetchTheme() {
      const dynamicPalette = await formatThemeForReservedArea();
      const theme = createTheme(dynamicPalette);
      setDynamicTheme(theme);
    }
    fetchTheme();
  }, []);
  const isSmallScreen = window.innerWidth <= 768;
  useEffect(() => {
    const id: any = localStorage.getItem("userID");
    BookingApi.getUserProfileByUserId(id).then((e) => {
      setProfileDetails(e.data.onlineUser[0]);
    });
  }, []);

  const [profileDetails, setProfileDetails] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    userImage: "",
  });

  function handleSubmit(event) {
    const id: any = localStorage.getItem("userID");
    event.preventDefault();
    BookingApi.UpdateUserProfileById(id, profileDetails).then((e) => {
      if (e.data.status) {
        setModalMessage("User Updated Successfully");
        setOpenModal(true);
      } else {
        setModalMessage("Unable to Update");
        setOpenModal(true);
        window.location.reload();
      }
      return e.data;
    });
  }
  const [showReserve, setShowReserve] = useState<boolean>(
    !!appSettings?.reservedArea
  );
  useEffect(() => {
    BookingApi.getAppSetting().then((e) => {
      setShowReserve(e.reservedArea);
    });
  }, [showReserve]);

  const handlePhoneNo = (event) => {
    setProfileDetails({ ...profileDetails, phoneNumber: event.target.value });
  };
  const handleFirstName = (event) => {
    setProfileDetails({ ...profileDetails, firstName: event.target.value });
  };
  const handleLastName = (event) => {
    setProfileDetails({ ...profileDetails, lastName: event.target.value });
  };
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const requestOnClose = () => {
    setOpenModal(false);
    setModalMessage("");
  };
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;

  return (
    <div className="flex-row md:flex">
      {showReserve && !isSmallScreen && !istabletScreen && (
        <div className="!w-780" style={{ width: "300px" }}>
          <BookingDetalisListSideNavigation />
        </div>
      )}
      {showReserve && (isSmallScreen || istabletScreen) && (
        <CollapsibleNavbar callFrom="showProfile" />
      )}
      {showReserve ? (
        <div className="flex flex-col pt-0 md:pt-12">
          <div
            className={`text-[#333] mb-4 w-[300px] ${
              isSmallScreen ? "ml-5" : "ml-16 whitespace-nowrap"
            }`}
          >
            <h1 className="text-[28px] font-normal">
              {t("Account information")}
            </h1>
            <h3 className="text-[20px] text-[#838181]">
              {t(
                "Your account information is here you can edit your details from here"
              )}
            </h3>
          </div>
          <form
            className={`${!isSmallScreen ? "ml-[30px]" : "ml-0"}`}
            onSubmit={handleSubmit}
          >
            <Container component="main" maxWidth="xs">
              <Card sx={{ minWidth: !isSmallScreen ? 600 : 0 }}>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  boxShadow={3}
                >
                  <Grid item xs={3}>
                    <Grid container alignItems="center">
                      <Card sx={{ minWidth: !isSmallScreen ? 440 : 300 }}>
                        <Box
                          sx={{
                            marginBottom: 5,
                            padding: 2,
                            display: "flex",
                          }}
                        >
                          {!isSmallScreen && (
                            <div className="flex">
                              <Avatar
                                src="/assets/images/AvtarProfilePage.png"
                                sx={{
                                  margin: 2,
                                  width: 70,
                                  height: 70,
                                }}
                              />
                            </div>
                          )}
                          <div className="p-4">
                            {isSmallScreen && (
                              <div className="justify-center">
                                <Avatar
                                  src="/assets/images/AvtarProfilePage.png"
                                  sx={{
                                    margin: 2,
                                    width: 70,
                                    height: 70,
                                  }}
                                />
                              </div>
                            )}
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              label="First Name"
                              value={profileDetails?.firstName}
                              name="firstName"
                              onChange={handleFirstName}
                            />

                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              name="lastName"
                              label="lastName"
                              value={profileDetails?.lastName}
                              id="lastName"
                              onChange={handleLastName}
                            />
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              label="Email"
                              disabled={true}
                              name="email"
                              value={profileDetails?.email}
                              id="email"
                            />
                            <TextField
                              name="phoneNumber"
                              id="phoneNumber"
                              value={profileDetails?.phoneNumber}
                              margin="normal"
                              required
                              fullWidth
                              label="Enter Phone Number"
                              onChange={handlePhoneNo}
                            />
                            <div className="flex gap-4 flex-row-reverse">
                              <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                  gap: 3,
                                  fontWeight: "bold",
                                  bgcolor:
                                    dynamicTheme?.palette?.["primary"]?.main,
                                  textTransform: "capitalize",
                                }}
                                style={{
                                  backgroundColor:
                                    dynamicTheme?.palette?.["primary"]?.main,
                                }}
                              >
                                Save
                              </Button>

                              <Button
                                type="submit"
                                variant="outlined"
                                sx={{
                                  gap: 3,
                                  fontWeight: "bold",
                                  borderColor: "#000",
                                  textTransform: "capitalize",
                                }}
                                style={{ color: "#000" }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Container>
          </form>
        </div>
      ) : (
        <NotAuthorisedPage />
      )}
      <Dialog
        open={openModal}
        onClose={requestOnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle style={{ padding: "3rem" }}>{modalMessage}</DialogTitle>

        <DialogActions>
          <AppStandardButton variant="outlined" onClick={requestOnClose}>
            <p className="text-black normal-case">Close</p>
          </AppStandardButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
