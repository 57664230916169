import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import BookingApi from "../../API/Apis/BookingApi";
import { useNavigate } from "react-router-dom";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import { AppStandardButton } from "../../components/UI/AppButtons";
import NotAuthorisedPage from "../notAuthorisedPage";
import { t } from "i18next";
import DefaultPage from "../../components/Layouts/DefaultPage";
import BookingGetters from "../../store/onlineBooking/getters";
import theme, { formatThemeForReservedArea } from "../../config/MuiColorThemes";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { Card, CardHeader, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CollapsibleNavbar from "../../components/Layouts/ReservedAreaDashboard/CollapseNavbar";

export default function CustomerDetailsList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const appSettings = BookingGetters.getAppSettings();
  const [dynamicTheme, setDynamicTheme] = useState<Theme | null>(null);

  useEffect(() => {
    async function fetchTheme() {
      const dynamicPalette = await formatThemeForReservedArea();
      const theme = createTheme(dynamicPalette);
      setDynamicTheme(theme);
    }
    fetchTheme();
  }, []);
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  useEffect(() => {
    setShowCustomerDetails(!!appSettings?.reservedArea);
  }, [appSettings]);
  const [totalCustomers, setTotalCustomers] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [data, setCustomerDetails] = useState([]);

  useEffect(() => {
    const id: any = localStorage.getItem("userID");
    const offset = page * rowsPerPage;
    BookingApi.getCustomerDeatilsByUserid(id, offset, rowsPerPage).then((e) => {
      setTotalCustomers(e.data.total);
      setCustomerDetails(e.data.customerList);
    });
  }, [page, rowsPerPage]);
  const isSmallScreen = window.innerWidth <= 768;
  const [userId] = useState(localStorage.getItem("userID"));
  const [reservedArea, setReservedArea] = useState(false);
  useEffect(() => {
    if (userId != null && userId != "null" && userId != "") {
      setReservedArea(true);
    } else {
      setReservedArea(false);
    }
  }, [userId]);
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;
  return (
    <ThemeProvider theme={dynamicTheme || theme}>
      <div>
        {showCustomerDetails ? (
          <div className="flex-row md:flex">
            {!isSmallScreen && !istabletScreen && (
              <div className="!w-780" style={{ width: "300px" }}>
                <BookingDetalisListSideNavigation />
              </div>
            )}
            {(isSmallScreen || istabletScreen) && (
              <CollapsibleNavbar callFrom="showReservations" />
            )}
            <div
              className="rounded-sm pt-0 md:pt-12"
              style={
                istabletScreen
                  ? { width: "100%" }
                  : isSmallScreen
                  ? { width: "90%" }
                  : { width: "75%" }
              }
            >
              <div className="text-[#333] px-8 max-w-screen-xl m-auto">
                <div className={"flex justify-between"}>
                  <p className="text-[24px] font-medium leading-snug">
                    {t("Customer Details")}
                  </p>
                  {!isSmallScreen && (
                    <AppStandardButton
                      href="/"
                      className={"flex mr-1"}
                      variant="contained"
                      style={{
                        width: "150px",
                        backgroundColor: "primary",
                        borderRadius: "10px",
                      }}
                    >
                      <span className="normal-case">Book a Car</span>
                    </AppStandardButton>
                  )}
                </div>
                <p className="text-[#838181] text-[16px]">
                  {t("Here is your customer details")}
                </p>
              </div>
              {!isSmallScreen ? (
                data.length === 0 ? (
                  <DefaultPage message="You have not created any booking yet. To create new Booking, please click on the Book a car button" />
                ) : (
                  <>
                    <TableContainer className="!shadow-grey-400 !rounded-2xl !border-solid !border-3 p-4">
                      <Table
                        className="!shadow-2xl rounded-2xl !border-solid !border-2 !border-grey-400"
                        aria-label="simple table"
                        stickyHeader
                      >
                        <TableHead className="!bg-[primary] rounded-2xl">
                          <TableRow className="!bg-[primary]">
                            {[
                              "Full name",
                              "Email",
                              "Phone",
                              "Company Name",
                            ].map((header, index) => (
                              <TableCell
                                key={index}
                                className={`!text-[15px] ${
                                  index === 0 ? "rounded-tl-2xl" : ""
                                } ${index === 3 ? "rounded-tr-2xl" : ""}`}
                                style={{
                                  backgroundColor:
                                    dynamicTheme?.palette?.primary?.main,
                                  color:
                                    dynamicTheme?.palette?.primary
                                      ?.contrastText,
                                }}
                              >
                                {t(header)}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((e, i) => (
                            <TableRow key={e.id || i}>
                              <TableCell
                                className="!text-[12px] rounded-bl-2xl"
                                component="th"
                                scope="e"
                              >
                                <ul>
                                  <li>
                                    {e.name || ""} {e.surname}
                                  </li>
                                </ul>
                              </TableCell>
                              <TableCell className="!text-[12px]">
                                {e.email || ""}
                              </TableCell>
                              <TableCell className="!text-[12px]">
                                {e.phoneNumb1 || ""}
                              </TableCell>
                              <TableCell className="!text-[12px] rounded-br-2xl">
                                {e.isPhysicalPerson === "true"
                                  ? ""
                                  : e.companyName || ""}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[8]}
                      component="div"
                      count={totalCustomers}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                )
              ) : data.length === 0 ? (
                <DefaultPage message="You have not created any booking yet. To create new Booking, please click on the Book a car button" />
              ) : (
                <Grid container spacing={2}>
                  {data.map((e, i) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={e.id || i}
                      style={{ borderRadius: "10px 10px 0px 0px" }}
                    >
                      <Card
                        className="!shadow-2xl rounded-2xl !border-solid !border-2 !border-grey-400 w-full ml-5 whitespace-nowrap "
                        style={{ borderRadius: "10px 10px 10px 10px" }}
                      >
                        <CardHeader
                          title={`${e.name || ""} ${e.surname || ""}`}
                          style={{
                            color: dynamicTheme?.palette?.primary?.contrastText,
                            backgroundColor:
                              dynamicTheme?.palette?.primary?.main,
                            height: "40px",
                            padding: "8px 16px",
                            lineHeight: "1.5",
                            borderRadius: "10px 10px 0px 0px",
                          }}
                          titleTypographyProps={{
                            style: {
                              color:
                                dynamicTheme?.palette?.primary?.contrastText,
                            },
                          }}
                        />
                        <CardContent>
                          {[
                            {
                              label: "Full name",
                              value: `${e.name || ""} ${e.surname || ""}`,
                            },
                            { label: "Email", value: e.email || "" },
                            { label: "Phone", value: e.phoneNumb1 || "" },
                            {
                              label: "Company Name",
                              value:
                                e.isPhysicalPerson === "true"
                                  ? ""
                                  : e.companyName || "",
                            },
                          ].map((field, index) => (
                            <React.Fragment key={index}>
                              <Typography
                                className="!text-[15px]"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  fontFamily:
                                    "MBCorpoSText-Regular-Web-Text-Regular",
                                }}
                                gutterBottom
                              >
                                <span>{t(field.label)}</span>
                                <span>{field.value}</span>
                              </Typography>
                              {index < 3 && <hr />}
                            </React.Fragment>
                          ))}
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </div>
          </div>
        ) : (
          <NotAuthorisedPage />
        )}
      </div>
    </ThemeProvider>
  );
}
