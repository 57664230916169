import * as React from "react";

import {
  DefaultValuesDetailsForm,
  DetailsFormValues,
} from "../WebCheckInForms/WebCheckInForms.props";

import { AppInputSwitchProps } from "../AppInputSwitcher/AppInputSwitcher.props";
import { AppInputSwitcher } from "../AppInputSwitcher/AppInputSwitcher";
import { DriverFormFields } from "../WebCheckInForms/FormKeys";
import { DriverFormProps } from "./DriverFormProps";
import { FormFieldErrorMessage } from "../../UI/FormFieldErrorMessage/FormFieldErrorMessage";
import { ReadOnlyIfInitialValuesNotProvided } from "../WebCheckInForms/FormOptions";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { useTranslation } from "react-i18next";
import { useWebCheckInForm } from "../../../hooks/DriverWebCheckInForm";
import { AddDocumentsWithPreview } from "../../UI/AllWebCheckInForms/DocumentUpload";
import { t } from "i18next";
import i18n from "../../../config/i18n";

export function DriverAddForm({
  driverDetails = {},
  initialValues = { ...DefaultValuesDetailsForm },

  onChange = () => {},
  onSubmit = () => {},
  children = <></>,
  documentTypes,
}: DriverFormProps) {
  const [
    {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
    },
    driverFormFields,
  ] = useWebCheckInForm(
    DriverFormFields,
    {
      ...Object.keys(driverDetails).reduce((prevValue, currentKey) => {
        prevValue[currentKey] = initialValues[currentKey]
          ? initialValues[currentKey]
          : driverDetails[currentKey] || DefaultValuesDetailsForm[currentKey];

        return prevValue;
      }, {}),
    } as DetailsFormValues,
    (e) => {
      onChange({ ...DefaultValuesDetailsForm, ...e });
    }
  );
  const { t: translate } = useTranslation();
  const handleCountryChange = (newCountryValue) => {
    setValue("country", newCountryValue || "");
    setValue("city", "");
    setValue("state", "");
  };
  const requiredFields = ["firstName", "lastName", "email", "releaseDate"];
  return (
    <>
      <form
        onSubmit={handleSubmit((e) => {
          //  console.log(e);
          setTimeout(onSubmit, 1000, { ...driverDetails, ...e });
        })}
      >
        <div className={"grid grid-cols-2 gap-4 mt-2"}>
          {Object.values(driverFormFields).map((el: AppInputSwitchProps) => {
            const readOnly =
              el.readOnly ||
              (ReadOnlyIfInitialValuesNotProvided.includes(el.key) &&
                !!initialValues[el.key]);
            const options: RegisterOptions = {
              required: {
                value:
                  !!el.required && initialValues[el.key] == null && !readOnly,
                message: "This is a required field",
              },
            };
            if (el.type === "email") {
              options.pattern = {
                value: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+$/,
                message: "Please provide a valid email",
              };
            }
            if (el.key === driverDetails?.ssn?.key) {
              options.maxLength = {
                value: 16,
                message: translate("Please provide a valid SSN"),
              };
              options.minLength = {
                value: 16,
                message: translate("Please provide a valid SSN"),
              };
            }
            if (el.key == "document") {
              el.type = "select";
              el.variant = "with-search";
              el.readOnly = false;
              el.options = documentTypes;
            }
            const genderMap = {
              MALE: {
                it: "MASCHIO",
                en: "MALE",
              },
              FEMALE: {
                it: "FEMMINA",
                en: "FEMALE",
              },
            };
            return (
              <div className="w-full h-full" key={el.key}>
                <AppInputSwitcher
                  {...el}
                  {...register(el.key, {
                    ...options,
                  })}
                  required={false}
                  onChange={() => {}}
                  className={`h-[80px] ${
                    el.key === "telephone" ? "mt-[-14px]" : ""
                  }`}
                  // label={translate(el.label || "")}
                  label={
                    <>
                      {translate(el.label || "")}
                      {requiredFields.includes(el.key) && (
                        <span style={{ color: "red" }}> *</span>
                      )}
                    </>
                  }
                  readOnly={readOnly}
                  placeholder={translate(el.placeholder || "")}
                  /* options={el.options || []}*/
                  options={
                    el.key === "sex"
                      ? [translate("MALE"), translate("FEMALE")]
                      : el.options || []
                  }
                  value={
                    genderMap[getValues(el.key)]?.[i18n.language] ||
                    getValues(el.key) ||
                    ""
                  }
                  onchange={(e) => {
                    if (el.key == "country") {
                      handleCountryChange(e);
                    }
                    setValue(el.key, e, {
                      // shouldValidate: true,
                    });
                  }}
                />
                <FormFieldErrorMessage>
                  {errors[el.key]?.message}
                </FormFieldErrorMessage>
              </div>
            );
          })}
        </div>
        {children}
      </form>
    </>
  );
}
