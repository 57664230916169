import { CloseTwoTone, DoneTwoTone } from "@mui/icons-material";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import BookingGetters from "../../../store/onlineBooking/getters";
import { InsuranceCardProps } from "./Insurance.props";
import React, { useEffect, useRef, useState } from "react";
import AppButton from "../../UI/AppButtons";
import { t } from "i18next";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { differenceInDays, format } from "date-fns";
import { useTranslation } from "react-i18next";
import { TextResource } from "../../../@types/DTO/AppSetting";

function InsuranceCardsLayout({
  insurances,
  onSelected,
  selectedInsuranceIndex = -1,
  secondaryColor = "",
  cardBackgroundColor = "",
  selectedCardBackgroundColor = "",
  cardTextColor = "",
  selectedCardTextColor = "",
  fillToCard,
  selectedGroup,
  insuranceBody,
}: InsuranceCardProps) {
  const isSmallScreen = window.innerWidth <= 768;
  const allInsuranceFeatures: Record<string, boolean> = {};
  const allInsurances = insurances.map((el) => {
    const availableInsurances = el.features.reduce((prev, current) => {
      const feature = current.toLowerCase();
      prev[feature] = true;
      allInsuranceFeatures[feature] = true;
      return prev;
    }, {});
    return {
      bookingText: undefined,
      ...el,
      availableInsurances,
    };
  });
  const appSettings = BookingGetters.getAppSettings();
  const defaultCurrency = appSettings?.defaultCurrency;
  const scrollToTop = () => {
    window.parent.postMessage(
      {
        event_id: "scrollToTop",
      },
      "*"
    );
  };
  const currencyDetails = BookingGetters.getCurrency();
  const boxShadow = "shadow-lg";
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const handleCardClick = (index: number) => {
    if (expandedIndex == index) {
      setExpandedIndex(-1);
      onSelected(-1);
    } else {
      setExpandedIndex(index);
      onSelected(index);
    }
  };

  const [expandedCardIndex, setExpandedCardIndex] = useState<number[]>([]);
  const showInsuranceDetails = (value: number, insurance) => {
    setExpandedCardIndex((prevExpandedCardIndex) => {
      const index = prevExpandedCardIndex.indexOf(value);
      if (index !== -1) {
        return prevExpandedCardIndex.filter((item) => item !== value);
      } else {
        return [...prevExpandedCardIndex, value];
      }
    });
  };
  const ReservationDetails = BookingGetters.getBookingDetails();
  const [startDate] = useState(
    new Date(
      format(
        new Date(ReservationDetails.pickUpDateAndTime || Date.now()),
        "yyyy-MM-dd"
      )
    )
  );
  const [endDate] = useState(
    new Date(
      format(
        new Date(ReservationDetails.dropOffDateAndTime || Date.now()),
        "yyyy-MM-dd"
      )
    )
  );
  const { t: translate } = useTranslation();
  const [numberOfDays] = useState(differenceInDays(endDate, startDate));
  return (
    <>
      <h2
        className="text-black text-xl font-semibold mb-2"
        style={{ gridColumn: "1 / -1" }}
      >
        {t("Add coverage")}
      </h2>
      <div className="flex flex-wrap justify-center items-center text-black">
        {allInsurances.map((el, index) => (
          <div
            className={`${boxShadow}  m-8 p-8 cursor-pointer transition-transform duration-300 flex-1 flex flex-col justify-center items-center 
            hover:-translate-y-2 rounded-3xl bg-white`}
            key={index}
            style={{
              flexBasis: "290px", // Ensure cards have a consistent width
              maxWidth: "290px", // Prevent cards from expanding beyond this width
              minWidth: "290px", // Prevent cards from shrinking below this width
              flexGrow: 0, // Prevent cards from growing to fill available space
            }}
          >
            <button
              id={"showBookText" + index}
              onClick={() => showInsuranceDetails(index, el.name)}
            >
              {expandedCardIndex.includes(index) ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </button>
            <h5
              className="text-xl font-semibold text-center mb-2"
              style={{ color: secondaryColor }}
            >
              {el.name}
            </h5>
            <p
              className={`text-lg mb-2 font-bold`}
              style={{ color: selectedCardBackgroundColor }}
            >
              {defaultCurrency ||
                formatPriceWithCurrencySymbol(currencyDetails).symbol + " "}
              {"  "}
              {numberOfDays == 0
                ? el.pricePerDayInUSD
                : (parseFloat(el.pricePerDayInUSD) / numberOfDays)?.toFixed(
                    2
                  )}{" "}
              {translate("/day")}
            </p>

            <div
              className="text-center"
              style={
                expandedCardIndex.includes(index)
                  ? isSmallScreen
                    ? { display: "block" }
                    : { display: "none" }
                  : isSmallScreen
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              {insuranceBody && insuranceBody[el.name] ? (
                <div
                  style={{ whiteSpace: "pre-line", fontSize: "small" }}
                  dangerouslySetInnerHTML={{
                    __html: insuranceBody[el.name],
                  }}
                />
              ) : (
                Object.keys(allInsuranceFeatures).map((feature, i) => (
                  <div key={i} className={`items-center mb-2`}>
                    <div className={"flex"}>
                      {feature.trim() !== "" &&
                        (el.availableInsurances[feature] ? (
                          <DoneTwoTone className="text-green-800 font-bold mr-2" />
                        ) : (
                          <CloseTwoTone className="text-red-800 mr-2" />
                        ))}

                      <div
                        className="text-md"
                        style={{ textAlign: "left", fontWeight: "500" }}
                      >
                        {feature}
                      </div>
                    </div>
                    {el.bookingText &&
                      el.bookingText[
                        feature.toUpperCase() + ":" + el.name.toUpperCase()
                      ] && (
                        <div>
                          <p
                            style={{
                              textAlign: "left",
                              fontSize: "small",
                              marginLeft: "15%",
                            }}
                          >
                            {
                              <div
                                style={{
                                  whiteSpace: "pre-line",
                                  fontSize: "small",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    el.bookingText[
                                      feature.toUpperCase() +
                                        ":" +
                                        el.name.toUpperCase()
                                    ],
                                }}
                              />
                            }
                          </p>
                        </div>
                      )}
                  </div>
                ))
              )}
            </div>

            <AppButton
              className="h-14 w-31"
              color={
                selectedInsuranceIndex == index ? "primary" : "secondaryColor"
              }
              onClick={() => {
                handleCardClick(index);
                // window.scrollTo(0, 0);
                scrollToTop();
              }}
              style={{ borderRadius: "5px", marginTop: "9px" }}
            >
              <p className="w-full text-center">{t("Select")}</p>
            </AppButton>
          </div>
        ))}
      </div>
    </>
  );
}
export { InsuranceCardsLayout };
