import React, { useEffect, useState } from "react";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import NotAuthorisedPage from "../notAuthorisedPage";
import ReservationCard from "../../components/Layouts/ReservationsCard/ReservationsCards";
import { t } from "i18next";
import { AppStandardButton } from "../../components/UI/AppButtons";
import BookingGetters from "../../store/onlineBooking/getters";
import CollapsibleNavbar from "../../components/Layouts/ReservedAreaDashboard/CollapseNavbar";

export default function MyReservedBookings() {
  const AppSettings = BookingGetters.getAppSettings();
  const isSmallScreen = window.innerWidth <= 768;
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;
  const [showReservedBooking, setShowReservedBooking] = useState(false);
  useEffect(() => {
    setShowReservedBooking(!!AppSettings?.reservedArea);
  }, [AppSettings]);
  return (
    <div>
      {showReservedBooking ? (
        <div className="flex-row md:flex">
          {!isSmallScreen && !istabletScreen && (
            <div className="!w-780" style={{ width: "300px" }}>
              <BookingDetalisListSideNavigation />
            </div>
          )}
          {(isSmallScreen || istabletScreen) && (
            <CollapsibleNavbar callFrom="showReservations" />
          )}
          <div
            className="rounded-sm pt-0 md:pt-12"
            style={
              istabletScreen
                ? { width: "100%" }
                : isSmallScreen
                ? { width: "90%" }
                : { width: "75%" }
            }
          >
            <div className="text-[#333] px-8 max-w-screen-xl m-auto">
              <div className={"flex justify-between"}>
                <p className="text-[24px] font-medium leading-snug">
                  {t("Booking Details")}
                </p>
                {!isSmallScreen && (
                  <AppStandardButton
                    href="/"
                    className={"flex mr-1"}
                    variant="contained"
                    style={{
                      width: "150px",
                      borderRadius: "10px",
                    }}
                  >
                    <span className="normal-case">Book a Car</span>
                  </AppStandardButton>
                )}
              </div>
              <p className="text-[#838181] text-[16px]">
                {t("Here is your next reserve bookings.")}
              </p>
            </div>
            <div className={`p-4`}>
              <ReservationCard></ReservationCard>
            </div>
          </div>
        </div>
      ) : (
        <NotAuthorisedPage />
      )}
    </div>
  );
}
