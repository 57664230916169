import AppStandardButton from "../../components/UI/AppButtons";
import { BookingInformationRow } from "../../components/Layouts/BookingInformation/BookingInformation";
import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { BookingApi } from "../../API/Apis/BookingApi";
import { NexiChargeTransactionDetails } from "../../@types/Booking/nexiTransactionDetails";
import TagManager from "react-gtm-module";
import BookingGetters from "../../store/onlineBooking/getters";
import { Vehicle } from "../../@types/DTO/Vehicles";
import { SelectedVehicleDetails } from "../../@types/Booking/SelectedVehicleDetails";
import { TextResource } from "../../@types/DTO/AppSetting";
import { formatTheme } from "../../config/MuiColorThemes";
import { createTheme } from "@mui/material/styles";
import i18n from "../../config/i18n";
export function WireTransferConfirm() {
  const appSettings = BookingGetters.getAppSettings();
  const redirectWebCheckIn = appSettings && appSettings?.redirectWebCheckIn;
  const HideOneButton =
    appSettings && appSettings?.hideButtonAtConfirmationPage;
  const paymentDetails = BookingGetters.getSelectedVehicleDetails();
  const price = paymentDetails.paymentDetails.price;
  const [searchParams] = useSearchParams();
  const dbId = searchParams.get("dbId");

  const [showGtagManagerEvent, setGtagManagerEvent] = useState(
    appSettings?.gtagManagerEvent || ""
  );
  const [showGTagManagerSendTo, setGTagManagerSendTo] = useState(
    appSettings?.gTagManagerSendTo || ""
  );
  const [showGTagManagerCurrency, setGTagManagerCurrency] = useState(
    appSettings?.gTagManagerCurrency || ""
  );
  const [isTagManagerDataLayerEnabled, setIsTagManagerDataLayerEnabled] =
    useState(false);
  const [showGtagManagerValue, setGtagManagerValue] = useState(false);
  const [showGTagManagerTransactionId, setGTagManagerTransactionId] =
    useState(false);
  const [tagManagerId, setTagManagerId] = useState("");
  const [isTagManagerEnabled, setIsTagManagerEnabled] = useState(false);
  const showPrice = showGtagManagerValue ? price : null;
  const showDbId = showGTagManagerTransactionId ? dbId : null;
  const textResource = BookingGetters.getTextResource();
  const [confirmationPageLabel, setConfirmationPageLabel] =
    useState<String>("");
  useEffect(() => {
    const data: TextResource[] = textResource?.data ?? [];
    const confirmationPageTextData = data.find(
      (item) => item.key === "ConfirmationPageTextLabel"
    );
    setConfirmationPageLabel(
      confirmationPageTextData?.body ||
        t("Congratulations! Your Booking Is Completed")
    );
  });
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const primaryColor = dynamicTheme?.palette["primary"]?.main;
  const secondaryColor = dynamicTheme?.palette["secondaryColor"]?.main;
  useEffect(() => {
    setIsTagManagerDataLayerEnabled(!!appSettings?.enableDataLayer);
    setGTagManagerCurrency(appSettings?.gTagManagerCurrency || "");
    setGTagManagerSendTo(appSettings?.gTagManagerSendTo || "");
    setGtagManagerEvent(appSettings?.gtagManagerEvent || "");
    setGtagManagerValue(!!appSettings?.enableGtagManagerValue);
    setGTagManagerTransactionId(!!appSettings?.enableGTagManagerTranscationId);
    setIsTagManagerEnabled(!!appSettings?.enableTagManager);
    setTagManagerId(appSettings?.tagManagerId || "");
  }, [appSettings]);

  useEffect(() => {
    if (isTagManagerDataLayerEnabled) {
      const tagManagerArgs = {
        dataLayer: {
          event: showGtagManagerEvent,
          send_to: showGTagManagerSendTo,
          value: showPrice,
          currency: showGTagManagerCurrency,
          transaction_id: showDbId,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [isTagManagerDataLayerEnabled]);
  useEffect(() => {
    if (isTagManagerEnabled && tagManagerId != "") {
      const tagManagerArgs = {
        gtmId: tagManagerId,
      };
      TagManager.initialize(tagManagerArgs); // google tag manager integrated along with the preferences
    }
  }, [tagManagerId, isTagManagerEnabled]);

  return (
    <section className="min-h-[80vh] box-border px-10 md:px-44  flex flex-col border-t-2 border-solid border-grey-300">
      <header className="grow flex flex-col items-center justif-center gap-4 box-border py-8">
        <img src="/assets/images/success-icon@2x.png" className="h-36 w-36" />
        <h1
          className="text-3xl font-bold py-2"
          style={{ color: secondaryColor }}
        >
          {confirmationPageLabel}
        </h1>
        <p className="font-bold text-black-600"></p>
        <div className="flex flex-col-2 ">
          {" "}
          <AppStandardButton
            href="/embed"
            className={"w-fit py-4 mx-0 md:mx-8"}
            variant="outlined"
            /* color="secondaryColor"*/
            style={{ borderRadius: "5", color: primaryColor }}
          >
            {/* Go to Account */}
            <span
              className="normal-case p-1 text-lg"
              style={{ color: secondaryColor }}
            >
              {" "}
              Homepage
            </span>
          </AppStandardButton>
          {!redirectWebCheckIn
            ? !HideOneButton && (
                <AppStandardButton
                  href="/embed"
                  className={"w-fit py-4 mx-2 md:mx-8"}
                  /*  color="secondaryColor"*/
                  variant="outlined"
                  style={{ borderRadius: "5", color: primaryColor }}
                >
                  {/*  Go to Account*/}
                  <span
                    className="normal-case p-1 text-lg"
                    style={{ color: secondaryColor }}
                  >
                    {" "}
                    Dashboard
                  </span>
                </AppStandardButton>
              )
            : !HideOneButton && (
                <AppStandardButton
                  href={`/booking/web-check-in?lang=${i18n.language}`}
                  className={"w-fit py-4 mx-2 md:mx-8"}
                  /*  color="primary"*/
                  variant="outlined"
                  style={{ borderRadius: "5", color: primaryColor }}
                >
                  <span
                    className="normal-case p-1 text-lg whitespace-nowrap"
                    style={{ color: secondaryColor }}
                  >
                    {" "}
                    Web Check-In
                  </span>
                </AppStandardButton>
              )}
        </div>
      </header>
      <main></main>
      <footer className="text-gray-600 box-border pb-32">
        {/* Web Checkin Info */}
        {/* Web Checkin Information */}

        <div className="box-border border-2 border-solid border-grey-200 px-8"></div>
      </footer>
    </section>
  );
}
