import {
  DefaultValuesDetailsForm,
  DetailsFormValues,
  GetReservationByReservationNumber,
} from "../../Layouts/WebCheckInForms/WebCheckInForms.props";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { AppInputSwitcher } from "../../Layouts/AppInputSwitcher/AppInputSwitcher";
import React from "react";
import { FormFieldErrorMessage } from "../FormFieldErrorMessage/FormFieldErrorMessage";
import { ThemeProvider } from "@mui/material/styles";

export function ByReservationNumberForm({
  values = { ...DefaultValuesDetailsForm },
  onChange = () => {},
  children = <></>,
  onSubmit = () => {},
  dynamicTheme,
}: GetReservationByReservationNumber): JSX.Element {
  const { t: translate } = useTranslation();
  const {
    formState: { errors },
    register,
    setValue,
    handleSubmit,
    getValues,
  } = useForm<DetailsFormValues>({
    mode: "onBlur",
    defaultValues: { ...DefaultValuesDetailsForm, ...values },
  });

  return (
    <ThemeProvider theme={dynamicTheme}>
      <form onSubmit={handleSubmit(onSubmit)} className={"flex flex-col gap-2"}>
        <AppInputSwitcher
          {...register("reservationDate", {
            required: {
              value: true,
              message: translate("Please provide a Reservation Date"),
            },
          })}
          label={translate("Reservation Date")}
          type="date"
          value={getValues("reservationDate") || null}
          key={"reservationDate"}
          onChange={() => {}}
          onchange={(e) => {
            try {
              setValue("reservationDate", new Date(e.toString()));
              onChange({
                ...values,
                reservationDate: new Date(e.toString()),
              });
            } catch (e) {
              setValue("reservationDate", null);
              onChange({
                ...values,
                reservationDate: null,
              });
            }
          }}
          options={[]}
        />
        <FormFieldErrorMessage>
          {errors?.reservationDate?.message}
        </FormFieldErrorMessage>
        <h2 className="py-2">{translate("Reservation Number")}</h2>
        <div className="flex flex-row gap-2 h-16">
          <AppInputSwitcher
            {...register("reservationPrefix", {
              required: {
                value: true,
                message: translate("Please provide a valid Prefix"),
              },
            })}
            onChange={() => {}}
            className="w-fit rounded-full"
            type="text"
            variant="material"
            placeholder={translate("Prefix")}
            value={getValues("reservationPrefix") || ""}
            onchange={(e) => {
              setValue("reservationPrefix", e || "");
              onChange({
                ...values,
                reservationPrefix: e?.toUpperCase(),
              });
            }}
            key={"reservationPrefix"}
            options={[]}
          />
          <AppInputSwitcher
            {...register("reservationNumber", {
              required: {
                value: true,
                message: translate("Please provide a valid Reservation Code"),
              },
            })}
            onChange={() => {}}
            type="text"
            variant="material"
            placeholder={translate("Reservation Number")}
            value={getValues("reservationNumber") || ""}
            key={"reservationCode"}
            onchange={(e) => {
              setValue("reservationNumber", e);
              onChange({
                ...values,
                reservationNumber: e,
              });
            }}
            options={[]}
          />
        </div>
        <FormFieldErrorMessage>
          {errors?.reservationNumber?.message}
        </FormFieldErrorMessage>
        <FormFieldErrorMessage>
          {errors?.reservationPrefix?.message}
        </FormFieldErrorMessage>
        {children}
      </form>
    </ThemeProvider>
  );
}
