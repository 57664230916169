import React, { ReactNode, useEffect, useState } from "react";
import { Carousel } from "../../Layouts/Carousel/Carousel";
import { IconButton } from "@mui/material";
import { DeleteForever, ImageOutlined } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { FormFieldErrorMessage } from "../FormFieldErrorMessage/FormFieldErrorMessage";

interface AddDocumentsWithPreviewProps {
  maxSizeInMB?: number;
  showPreview?: boolean;
  onChange?: (e: File[]) => void;
  children?: ReactNode;
  selectedDocs?: File[];
  currentDoc?: string[];
}

export function AddDocumentsWithPreview({
  onChange = () => {},
  showPreview = true,
  children = <></>,
  maxSizeInMB = 10,
  selectedDocs = [],
  currentDoc = [],
}: AddDocumentsWithPreviewProps) {
  const [allSelectedImages, setAllSelectedImages] =
    useState<File[]>(selectedDocs);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  function addToPreview(fileForPreview: File) {
    setAllSelectedImages((prev) => {
      if (fileForPreview) {
        return [...prev, fileForPreview];
      }
      return [...prev];
    });
  }
  useEffect(() => {
    // const debounce = setTimeout(() => {
    onChange(allSelectedImages);
    // }, 800);
    // return () => {
    //   clearTimeout(debounce);
    // };
  }, [allSelectedImages]);
  return (
    <div>
      <div>
        <label>
          <div className="flex justify-center border border-gray-700 rounded-md p-1 w-1/2 text-center hover:text-[#f9ca01] bg-gray-100 cursor-pointer">
            <svg
              className="w-8 h-8"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
            </svg>
            {/* <span className="text-red-500">Browse</span> */}
          </div>
          <input
            {...register("file", {
              // required: {
              //   value: !allSelectedImages.length,
              //   message: "Please provide at least 1 Document",
              // },
            })}
            required={false}
            type={"file"}
            // style={{ display: "none" }}
            accept={"image/*,.pdf"}
            className={" h-12 w-1/2  hidden "}
            onChange={async (e) => {
              if (e.target.files && e.target.files.length) {
                if (e.target.files[0]?.size > maxSizeInMB * 1024 * 1024) {
                  setError(
                    "file",
                    {
                      type: "size",
                      message:
                        "File size should not be greater than" +
                        maxSizeInMB +
                        "MB",
                    },
                    {
                      shouldFocus: true,
                    }
                  );
                } else {
                  addToPreview(e.target.files[0]);
                }
              }
            }}
          />
        </label>
      </div>
      {currentDoc.length > 0 && (
        <div id="documentPreviews">
          {Array.from(Array(currentDoc.length).keys()).map((index) => (
            <ImageOutlined key={index} style={{ fontSize: 48 }} />
          ))}
        </div>
      )}
      <FormFieldErrorMessage>{errors?.file?.message}</FormFieldErrorMessage>
      {showPreview && (
        <>
          {/*<h2>Selected Images</h2>*/}
          <div className={"w-full"}>
            {allSelectedImages.length > 0 && (
              <Carousel
                direction={"horizontal"}
                onSlideItemClick={() => {}}
                carouselItems={allSelectedImages.map((el, index) => {
                  if (el.type.includes("pdf")) {
                    return (
                      <div className={"relative"} key={index}>
                        <div className={"absolute top-0 right-0 z-10"}>
                          <IconButton
                            className={"h-8 w-8"}
                            onClick={() => {
                              setAllSelectedImages((prev) => {
                                prev.splice(index, 1);
                                return [...prev];
                              });
                            }}
                          >
                            <DeleteForever color={"error"} />
                          </IconButton>
                        </div>
                        <div
                          className={
                            "bg-red-200 h-full w-full box-border p-8 rounded-lg text-red-800"
                          }
                        >
                          PDF File
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div
                      className={
                        "relative hover:drop-shadow-md box-border m-2 cursor-pointer"
                      }
                      key={index}
                    >
                      <div className={"absolute top-0 right-0 z-10"}>
                        <IconButton
                          className={"h-8 w-8"}
                          onClick={() => {
                            setAllSelectedImages((prev) => {
                              prev.splice(index, 1);
                              return [...prev];
                            });
                          }}
                        >
                          <DeleteForever color={"error"} />
                        </IconButton>
                      </div>
                      <div className={"rounded-lg bg-white "}>
                        <img
                          className={"h-28 aspect-square p-4"}
                          src={URL.createObjectURL(el)}
                          alt={"File uploaded preview"}
                        />
                      </div>
                    </div>
                  );
                })}
              />
            )}
          </div>
        </>
      )}
      {children}
    </div>
  );
}
