import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";

import { AppStandardMultipleSelectionProps } from "./AppMultiSelection.props";
import { useMultiSelectCheckBoxStyles } from "./AppMultiSelection.style";

const AppStandardMultiSelectCheckBox = forwardRef(
  function AppStandardMultiSelectCheckBox(
    {
      value = [],
      options,
      header = "",
      required,
      onChange = () => {},
      color = "",
    }: AppStandardMultipleSelectionProps,
    ref: ForwardedRef<HTMLInputElement>
  ) {
    const styles = useMultiSelectCheckBoxStyles();
    const [selectedOptions, setSelectedOptions] = useState<string[]>(
      value || []
    );
    useEffect(() => {
      if (value?.length > 0) {
        setSelectedOptions(value);
      }
    }, [value]);

    async function handleClick(value: string) {
      let temp: string[] = selectedOptions || [];
      if (typeof temp === "string") {
        temp = [temp];
      }
      if (!selectedOptions?.includes(value)) {
        temp.push(value);
      } else {
        temp = temp.filter((el) => el != value);
      }
      setSelectedOptions(() => [...temp]);
      onChange(temp);
    }
    return (
      <FormControl>
        <FormLabel>{header}</FormLabel>
        <FormGroup className={styles.default}>
          {options?.map((el, index) => {
            let label: string = "";
            let value: string = "";
            if (typeof el == "string") {
              value = el;
              label = el;
            } else {
              value = el.value;
              label = el.label;
            }
            const renderLabelWithAsterisk = (label: string) => {
              const parts = label.split("*");
              return (
                <span>
                  <span style={{ color: "red" }}>*</span>
                  {parts[1]}
                </span>
              );
            };
            return (
              <div className="flex flex-row h-fit" key={index}>
                <FormControlLabel
                  aria-required={true}
                  label={
                    typeof label === "string" && label.startsWith("*")
                      ? renderLabelWithAsterisk(label)
                      : label
                  }
                  className="select-none"
                  ref={ref}
                  value={value}
                  control={
                    <Checkbox
                      required={
                        Array.isArray(required) ? required[index] : required
                      }
                      value={el}
                      color={color}
                      checked={selectedOptions.includes(value)}
                      onChange={async (e) => {
                        handleClick(value).then();
                      }}
                    />
                  }
                />
              </div>
            );
          })}
        </FormGroup>
      </FormControl>
    );
  }
);
export { AppStandardMultiSelectCheckBox };
