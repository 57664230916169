import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import { useDispatch } from "react-redux";
import BookingGetters from "../../store/onlineBooking/getters";
import { useWebCheckInForm } from "../../hooks/WebCheckInForm_2";
import { PrivateCustomerDetailFormField } from "./UserDetailsForm";
import {
  DefaultValuesDetailsForm,
  DetailsFormValues,
} from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";

function PrivatePersonalDetails({ userDetails, setUserDetails, dateError }) {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const appSettings = BookingGetters.getAppSettings();
  /*const [userDetails, setUserDetails] = useState<DetailsFormValues>({
    ...DefaultValuesDetailsForm,
  });*/
  const [formStates, formFields] = useWebCheckInForm(
    { ...PrivateCustomerDetailFormField },
    userDetails,
    (e) => {
      setUserDetails((prevDetails) => {
        return {
          ...prevDetails,
          customerType: "Private",
          ...e,
        };
      });
    }
  );
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(BOOKING_ACTIONS.setUserDetails(userDetails));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [userDetails, dispatch]);

  const handleCountryChange = () => {
    formStates.setValue("city", "");
    formStates.setValue("state", "");
  };
  const isSmallScreen = window.innerWidth <= 768;
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1300;

  return (
    <>
      <div className="bg-white box-border p-8 justify-center pt-[10px]">
        <div
          className={`grid sm:grid-cols-2 w-full ${
            istabletScreen ? "md:grid-cols-2" : "md:grid-cols-3"
          } gap-4`}
        >
          {Object.values(formFields).map((el) => {
            if (el.key === "flightNumber" && !appSettings?.flightNumber) {
              return null;
            }
            const padding =
              el.key === "ssnNumber" && isSmallScreen
                ? "pt-[10px]"
                : "pt-[0px]";
            return (
              <AppInputSwitcher
                {...el}
                key={el.key}
                required={true}
                placeholder={translate(el.placeholder || "")}
                label={translate(el.label || "")}
                className={`h-20 sm:w-full box-border sm:px-0 md:px-8 ${padding}`}
                value={userDetails[el.key] || formStates.getValues(el.key)}
                options={
                  el.key === "sex"
                    ? [translate("MALE"), translate("FEMALE")]
                    : el.options
                }
                dateError={dateError}
                onchange={(e) => {
                  if (el.key == "country") {
                    handleCountryChange();
                  }
                  formStates.setValue(el.key, e.toString(), {});
                }}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default PrivatePersonalDetails;
